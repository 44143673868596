import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Config from '../../config';
import Ajax from '../../common/ajax';
import Modal from '../../common/containers/Modal';

class CopyDocumentButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWaiting: false,
    };
    this.onCopyClick = this.onCopyClick.bind(this);
  }

  onCopyClick() {
    this.setState((s) => ({ ...s, showWaiting: true }));
    const url = `${Config.apiHost}documents/${this.props.document.id}/copy/`;
    Ajax.post(url)
      .done((response) => {
        if (this.props.onCopy) {
          this.props.onCopy(response.copy_id);
        }
      })
      .always(() => this.setState((s) => ({ ...s, showWaiting: false })));
  }

  render() {
    const { i18n } = this.props;
    const title = i18n.t('Fazer uma cópia');
    return (
      <>
        <a role="button" onClick={this.onCopyClick} title={title}>
          <i className="mdi mdi-copy" />
          &nbsp;
          {title}
        </a>
        <Modal title={title} show={this.state.showWaiting} isProcessing={true}>
          {i18n.t('Criando uma cópia do documento. Aguarde...')}
        </Modal>
      </>
    );
  }
}

CopyDocumentButton.propTypes = {
  document: PropTypes.object.isRequired,
  onCopy: PropTypes.func,
};

export default withNamespaces()(CopyDocumentButton);
