import React from 'react';
import PropTypes from 'prop-types';
import Waiting from './Waiting';
import Modal from './Modal';

export default class ModalConfirm extends React.Component {
  constructor(props) {
    super(props);
  }

  renderFooter() {
    return (
      <>
        <button
          type="button"
          className="btn btn-default"
          onClick={this.props.onCancel}
        >
          {this.props.cancelLabel}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.props.onConfirm}
        >
          {this.props.confirmLabel}
        </button>
      </>
    );
  }

  render() {
    const { onCancel, children, ...rest } = this.props;
    return (
      <Modal {...rest} onCancel={onCancel} footer={this.renderFooter()}>
        {children}
      </Modal>
    );
  }
}

ModalConfirm.propTypes = {
  ...Modal.propTypes,
  onConfirm: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
};

ModalConfirm.defaultProps = {
  ...Modal.defaultProps,
  confirmLabel: 'Confirmar',
  cancelLabel: 'Cancelar',
};
