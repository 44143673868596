import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import a4paper from '../a4paper.png';
import presentation from '../presentation.png';

class LazyImage extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.imgRef = React.createRef();
  }

  onChange(isVisible) {
    if (isVisible) {
      this.imgRef.current.src = this.imgRef.current.getAttribute('data-src');
    }
  }

  render() {
    const { src, use43Ratio, ...restProps } = this.props;
    return (
      <VisibilitySensor
        onChange={this.onChange}
        partialVisibility="top"
        scrollCheck={true}
        scrollThrottle={300}
        minTopValue={1}
      >
        <img ref={this.imgRef} src={use43Ratio ? presentation : a4paper} data-src={src} {...restProps} />
      </VisibilitySensor>
    );
  }
}

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  use43Ratio: PropTypes.bool,
};

export default LazyImage;
