import React from "react"
import PropTypes from "prop-types"
import Config from "../../config"
import Ajax from "../../common/ajax";
import {AlertFromResponse} from "../../common/components/Alert";
import ModalConfirm from "../../common/containers/ModalConfirm";
import {
    clearDjangoValidationMessages,
    showDjangoValidationMessages
} from "../../common/validation";
import { withNamespaces } from "react-i18next";

class SendPDFModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            response: null,
            form: {
                email: '',
                message: '',
            }
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.create = this.create.bind(this);
        this.clearMessages = this.clearMessages.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            this.setState(state => {
                return {
                    ...state,
                    response: null,
                };
            });
        }
    }

    clearMessages() {
        this.setState(state => {
            return {
                ...state,
                response: null,
            };
        });
    }

    create(evt) {
        this.setState({
            ...this.state,
            processing: true,
            response: null,
        });
        clearDjangoValidationMessages();
        const url = `${Config.apiHost}documents/${this.props.document.id}/send_pdf/`;
        Ajax.post(url, {...this.state.form}).done(data => {
            this.setState(state => {
                return {
                    ...state,
                    processing: false,
                    response: data,
                    form: {
                        email: '',
                        message: '',
                    }
                };
            });
        }).fail(jqXHR => {
            showDjangoValidationMessages(jqXHR.responseJSON);
            this.setState(state => {
                return {
                    ...state,
                    processing: false,
                    response: jqXHR,
                }
            });
        });
        evt.preventDefault();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [name]: value
            }
        });
    }

    render() {
        const { i18n } = this.props;

        return (
            <div>
                <ModalConfirm
                    title={i18n.t('Enviar') + ' PDF'}
                    show={this.props.show}
                    onConfirm={this.create}
                    onCancel={this.props.onCancel}
                    isProcessing={this.state.processing}
                    confirmLabel={i18n.t('Enviar')}
                >
                    {this.state.response &&
                        <AlertFromResponse response={this.state.response} />}

                    <form onSubmit={this.create}>
                        <div className="form-group">
                            <input type="email" name="email" required={true}
                                   className="form-control"
                                   maxLength="254" placeholder="E-mail"
                                   onChange={this.handleInputChange}
                                   value={this.state.form.email}/>
                        </div>
                        <div className="form-group">
                            <textarea name="message" id="id_message" rows="2"
                                      placeholder={i18n.t('Mensagem (opcional)')}
                                      cols="40" className="form-control"
                                      onChange={this.handleInputChange}
                                      value={this.state.form.message}>
                            </textarea>
                        </div>
                    </form>
                </ModalConfirm>
            </div>
        );
    }
}

SendPDFModal.propTypes = {
    document: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default withNamespaces()(SendPDFModal);