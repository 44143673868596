const locale = window.navigator.userLanguage || window.navigator.language;

const LocaleUtils = {
  fromNow: (value) => {
    return new Date(value).toLocaleString(locale);
  },
  calendar: (value) => {
    return new Date(value).toLocaleString(locale);
  },
  currency: (value, code = 'BRL') => {
    const options = {
      style: 'currency',
      currency: code,
    };
    return value.toLocaleString(locale, options);
  },
  number: function (value, minimumFractionDigits) {
    return value.toLocaleString(locale, { minimumFractionDigits });
  },
  currentLocale: () => locale,
  isPortuguese: () => locale.startsWith('pt'),
};

export default LocaleUtils;
